<template>
  <div id="page-user-list">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-else class="vx-card px-6 pb-6 pt-6">
      <vs-table hoverFlat ref="table" :data="filteredItems" stripe :max-items="itemsPerPage">
        
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

          <div class="ml-auto">
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mx-2">{{ $t('sort') }}: </span>
                <span class="capitalize mr-2">{{ $t(current) }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="opt in statusOptions" :key="opt" @click="current=opt">
                  <span>{{ $t(opt) }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentx * itemsPerPage - (itemsPerPage - 1) }} - {{ users.pagination.all - currentx * itemsPerPage > 0 ? currentx * itemsPerPage : users.pagination.all }} of {{ users.pagination.all }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="changeItemsPerPage(4)">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(15)">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(20)">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <div class="relative inline-block">
              <vs-input placeholder="Telefon belgi" class="rounded-lg" v-model="searchQuery"></vs-input>
              <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
            </div>

          </div>
        </div>

        <template slot="thead">
          <!-- <vs-th class="pr-0" sort-key="isExpress">#</vs-th> -->
          <vs-th class="" sort-key="code">{{ $t('fields.code') }}</vs-th>
          <vs-th class="pl-0" sort-key="name">{{ $t('fields.name') }}</vs-th>
          <vs-th class="hidden lg:table-cell" sort-key="phone">{{ $t('fields.phone') }}</vs-th>
          <vs-th class="hidden lg:table-cell" sort-key="email">{{ $t('fields.email') }}</vs-th>
          <vs-th sort-key="isVerified">{{ $t('props.isVerified') }}</vs-th>
          <vs-th sort-key="isBlocked">{{ $t('fields.active') }}?</vs-th>
          <vs-th sort-key="tasks_all">{{ $t('orders') }}</vs-th>
          <vs-th><span class="ml-auto">{{ $t('fields.actions') }}</span></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            
            <!-- <vs-td class="pr-0">
              {{ ((currentx - 1) * itemsPerPage) + (indextr + 1) }}
            </vs-td> -->
            
            <vs-td class="font-medium" :data="data[indextr].code">
              {{ data[indextr].id }}
            </vs-td>
            
            <vs-td class="pl-0" :data="data[indextr].name">
              {{ data[indextr].name || '---' }}
              <feather-icon v-if="data[indextr].isSupplier" icon="TruckIcon" svgClasses="w-5 h-5 text-primary" />
            </vs-td>

            <vs-td :data="data[indextr].phone" class="hidden lg:table-cell">
              {{ data[indextr].phone }}
            </vs-td>

            <vs-td :data="data[indextr].email" class="hidden lg:table-cell">
              {{ data[indextr].email || '---'}}
            </vs-td>
            <vs-td :data="data[indextr].isVerified">
              <vs-chip :color="tr.isVerified ? 'success' : 'danger'">
                <feather-icon v-if="tr.isVerified" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].isBlocked">
              <vs-chip :color="!tr.isBlocked ? 'primary' : 'danger'">
                <feather-icon v-if="!tr.isBlocked" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].orders_delivered">
              {{ data[indextr].orders_rejected }}/<strong>{{ data[indextr].orders_delivered }}</strong>
            </vs-td>

            <vs-td class="whitespace-no-wrap text-right">
              <feather-icon icon="EyeIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="" :title="$t('details')" @click.stop="showData(tr.uuid)" />
              <template v-if="$acl.check('superadmin')" >
                <feather-icon icon="EditIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" :title="$t('edit')" @click.stop="editData(tr.uuid)" />
                <!-- <feather-icon v-if="!tr.isBlocked" icon="LockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" title="Change courier status" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" /> -->
                <!-- <feather-icon v-else icon="UnlockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" title="Change courier status" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" /> -->
              </template>
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
      <vs-row class="mt-4">
        <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
      </vs-row>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      itemsPerPage: this.$route.query.limit || 15,
      itemsOffset: Number(this.$route.query.limit || 15) * (Number(this.$route.query.page || 1) - 1),
      current: this.$route.query.current || 'all',
      currentx: Number(this.$route.query.page || 1),
      users: { data: [], pagination: {} },
      statusOptions: ['all', 'active', 'blocked'],
      searchQuery: this.$route.query.q || '',
      isLoading: true,
      isMounted: false
    }
  },
  watch: {
    current (newVal) {
      const query = { ...this.$route.query, current: newVal }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ query })
      }
      this.fetchData()
    },
    searchQuery (newVal) {
      if (this.searchQuery.length > 2 || this.searchQuery.length === 0) {
        this.currentx = 1
        const query = { ...this.$route.query, q: String(newVal) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
        this.fetchData()
      }
    }
  },
  computed: {
    pages ()         { return Math.ceil(this.users.pagination.all / this.itemsPerPage) },
    filteredItems () { return this.users.data }
  },
  async created () {
    await this.fetchData()
    this.isMounted = true
  },
  methods: {
    showData (id) { this.$router.push(`/users/${id}`).catch(() => {}) },
    editData (id) { this.$router.push(`/users/${id}/edit`).catch(() => {}) },
    paginateTo (e) {
      if (this.isMounted) {
        this.itemsOffset = this.itemsPerPage * (this.currentx - 1)
        const query = { ...this.$route.query, page: String(e) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
        this.fetchData()
      }
    },
    changeItemsPerPage (count) {
      this.currentx = 1
      this.itemsPerPage = count
      const query = { ...this.$route.query, limit: String(count) }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ query })
      }
      this.fetchData()
    },
    async fetchData () {
      await this.$http.get('/users', {
        params: {
          limit: this.itemsPerPage,
          offset: this.itemsOffset,
          isBlocked: this.current === 'all' ? null : this.current === 'blocked',
          keyword: this.searchQuery
        }
      }).then(res => {
        this.users = res.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .vs-table--header {
    display: flex;
    flex-wrap: wrap-reverse;
    // margin-left: 1.5rem;
    // margin-right: 1.5rem;
    > span {
      display: flex;
      flex-grow: 1;
    }

    .vs-table--search{
      padding-top: 0;

      .vs-table--search-input {
        padding: 0.9rem 2.5rem;
        font-size: 1rem;

        &+i {
          left: 1rem;
        }

        &:focus+i {
          left: 1rem;
        }
      }
    }
  }
}
</style>
